$(function() {
	$('#content_project_id').on('change', function() {
		let project_id = $(this).val();
		let placeholders = $('.content-placeholders');

		$.get({
			url: '/portation/get_project_fields',
			data: { project_id },
			success: function (res) {
				placeholders.empty();

				if(res.fields && res.fields.length) {
					placeholders.html('<pre>' + res.fields.join('</pre><pre>') + '</pre>');
				}
			}
		})
	});
});