if (window.location.pathname.includes('views/edit') || window.location.pathname.includes('views/add')) {
  viewsEdit();
}

function viewsEdit() {
  // ---------------------------------------------------------
  // Elements.
  // ---------------------------------------------------------

  var $form = document.querySelector('form');
  var $types = document.querySelectorAll('[data-type]');
  var $images = document.querySelector('[data-images]');
  var $image = document.querySelector('[data-image]');
  var $separator = document.querySelector('[data-separator]');
  var $submitButtons = document.querySelectorAll('button[type=submit]');

  // ---------------------------------------------------------
  // Variables.
  // ---------------------------------------------------------

  var imagePrefix = $images ? $images.querySelector('[data-field-prefix]').value || 'Bild' : 'Bild';
  var formType = 'edit';

  // ---------------------------------------------------------
  // Event listeners.
  // ---------------------------------------------------------

  for (var $button of $submitButtons) {
    if ($button.value == 'add') formType = 'add';
    else if ($button.value == 'edit') formType = 'edit';
    $button.onclick = function (event) {
      event.preventDefault();
      submitForm(this.value);
    };
  }

  $form.onsubmit = function (event) {
    event.preventDefault();
  };

  for (var $type of $types) {
    $type.onchange = function () {
      submitForm();
    };
  }

  // Images.

  $images.querySelector('[data-field-start-time]').onchange = function updateImagesStartTime(
    event
  ) {
    view.start_time = event.target.value;
    renderImages();
  };

  $images.querySelector('[data-field-time-interval]').onchange = function updateImagesTimeInterval(
    event
  ) {
    view.time_interval = event.target.value;
    renderImages();
  };

  $images.querySelector('[data-field-begin-at]').onchange = function updateImagesBeginAt(event) {
    view.begin_at = Number(event.target.value.split(':')[0]);
    renderImages();
  };

  $images.querySelector('[data-field-upload]').onchange = function uploadImages(event) {
    var files = Array.from(event.target.files);
    var listLength = view.images.length + 1;
    for (var index = 0; index < files.length; index++) {
      var file = files[index];
      var listIndex = listLength + index + 1;
      if (getTime(listIndex) > 24) break;
      view.images.push({
        title: imagePrefix + ' ' + listIndex,
        url: URL.createObjectURL(file),
        file: file,
      });
    }
    submitForm();
  };

  // Image.

  $image.querySelector('[data-preview-field-title]').onchange = function updateImageTitle(event) {
    view.image.title = event.target.value;
  };

  $image.querySelector('[data-field-upload]').onchange = function uploadImage(event) {
    view.image.title = view.image.title || imagePrefix + ' ' + 1;
    view.image.url = event.target.files[0].name;
    view.image.file = event.target.files[0];
    submitForm();
  };

  // ---------------------------------------------------------
  // Init.
  // ---------------------------------------------------------

  renderImages();
  renderImage();

  // ---------------------------------------------------------
  // Functions.
  // ---------------------------------------------------------

  /**
   * Render image (View Type: 'None', 'Compass').
   */
  function renderImage() {
    $image.querySelector('[data-field-upload]').value = '';
    $image.querySelector('[data-preview-field-url]').value = view.image.url.split('/').pop();
    $image.querySelector('[data-preview-field-title]').value = view.image.title;
    $image.querySelector('[data-preview-image]').onload = function (event) {
      URL.revokeObjectURL(event.target.src);
    };
    $image.querySelector('[data-preview-image]').src = view.image.url;
    $image.querySelector('[data-preview-icon-link]').href = view.image.url;
    if (view.image.url) $image.querySelector('[data-preview-icon-link]').classList.remove('hide');
    $image.querySelector('[data-preview-field-url]').classList.remove('hide');
    $image.querySelector('[data-preview-card]').classList.remove('hide');
  }

  /**
   * Render images (View Type: 'Images')
   */
  function renderImages() {
    $images.querySelector(
      '[data-field-start-time] option[value="' + (view.start_time || 8) + '"]'
    ).selected = 'selected';
    $images.querySelector(
      '[data-field-time-interval] option[value="' + (view.time_interval || 1) + '"]'
    ).selected = 'selected';
    $images.querySelector('[data-preview-list]').textContent = '';
    $images.querySelector('[data-field-begin-at]').textContent = '';

    var $imageCard = $image.querySelector('[data-preview-card]').cloneNode(true);
    $imageCard.dataset.isLocked = true;
    $imageCard.querySelector('[data-preview-image]').onload = function (event) {
      URL.revokeObjectURL(event.target.src);
    };
    $imageCard.querySelector('[data-preview-image]').src = view.image.url;
    $imageCard.querySelector('[data-preview-field-url]').value = view.image.url.split('/').pop();
    $imageCard.querySelector('[data-preview-field-title]').onchange = function (event) {
      view.image.title = event.target.value;
    };
    $imageCard.querySelector('[data-preview-field-title]').value =
      view.image.title || imagePrefix + ' ' + 1;
    $imageCard.querySelector('[data-preview-icon-lock]').classList.remove('hide');
    $imageCard.querySelector('[data-preview-field-time]').value = getTime(0, true);
    $imageCard.querySelector('[data-preview-field-time]').classList.remove('hide');
    $imageCard.querySelector('[data-preview-button-upload]').classList.remove('hide');
    $imageCard.querySelector('[data-preview-button-upload]').onclick = function (event) {
      event.preventDefault();
      $image.querySelector('[data-field-upload]').click();
    };
    if (view.image.url)
      $imageCard.querySelector('[data-preview-icon-link]').classList.remove('hide');
    if (view.image.url) $imageCard.querySelector('[data-preview-icon-link]').href = view.image.url;
    appendBeginAtOption(0);
    appendSeparator(0);
    $images.querySelector('[data-preview-list]').appendChild($imageCard);

    for (var index = 0; index < view.images.length; index++) {
      var listIndex = index + 1;
      var image = view.images[index];
      if (getTime(listIndex) > 24) break;
      var $imagesCard = $image.querySelector('[data-preview-card]').cloneNode(true);
      $imagesCard.dataset.fileIndex = index;
      $imagesCard.dataset.listIndex = listIndex;
      $imagesCard.querySelector('[data-preview-field-title]').onchange = function (event) {
        image.title = event.target.value;
      };
      $imagesCard.querySelector('[data-preview-button-up]').onclick = function (event) {
        event.preventDefault();
        moveCardUp(event.target.parentNode.parentNode);
      };
      $imagesCard.querySelector('[data-preview-button-down]').onclick = function (event) {
        event.preventDefault();
        moveCardDown(event.target.parentNode.parentNode);
      };
      $imagesCard.querySelector('[data-preview-button-delete]').onclick = function (event) {
        event.preventDefault();
        deleteCard(event.target.parentNode.parentNode);
      };
      $imagesCard.querySelector('[data-preview-image]').onload = function (event) {
        URL.revokeObjectURL(event.target.src);
      };
      if (image.url) $imagesCard.querySelector('[data-preview-image]').src = image.url;
      if (image.url)
        $imagesCard.querySelector('[data-preview-field-url]').value = image.url.split('/').pop();
      if (image.url) $imagesCard.querySelector('[data-preview-icon-link]').href = image.url;
      $imagesCard.querySelector('[data-preview-field-title]').value =
        image.title || imagePrefix + ' ' + listIndex;
      $imagesCard.querySelector('[data-preview-field-time]').value = getTime(listIndex, true);
      $imagesCard.querySelector('[data-preview-field-time]').classList.remove('hide');
      $imagesCard.querySelector('[data-preview-button-up]').classList.remove('hide');
      $imagesCard.querySelector('[data-preview-button-down]').classList.remove('hide');
      $imagesCard.querySelector('[data-preview-button-delete]').classList.remove('hide');
      if (image.url) $imagesCard.querySelector('[data-preview-icon-link]').classList.remove('hide');
      appendBeginAtOption(listIndex);
      appendSeparator(listIndex);
      $images.querySelector('[data-preview-list]').appendChild($imagesCard);
    }
  }

  /**
   * Submits form with files.
   */
  function submitForm(action) {
    $image.remove();
    $images.remove();
    var formData = new FormData($form);
    var request = new XMLHttpRequest();
    formData.append('action', action || formType || 'edit');
    formData.append('start_time', view.start_time);
    formData.append('time_interval', view.time_interval);
    formData.append('begin_at', view.begin_at);
    formData.append('image[title]', view.image.title || imagePrefix + ' ' + 1);
    formData.append('image[url]', view.image.url);
    if (view.image.file) formData.append('image', view.image.file);

    for (var index = 0; index < view.images.length; index++) {
      var image = view.images[index];
      var listIndex = index + 1;
      if (getTime(listIndex) > 24) break;
      formData.append('images[' + listIndex + ']', image.file);
      formData.append(
        'images[' + listIndex + '][title]',
        image.title || imagePrefix + ' ' + listIndex
      );
      formData.append('images[' + listIndex + '][url]', image.url);
    }

    request.addEventListener('load', function onLoad() {
      if (request.status !== 200) alert(request.status + ' ' + request.statusText);
      else window.location = request.responseURL;
    });

    request.open('POST', window.location.pathname);
    request.send(formData);
  }

  /**
   * Delete card.
   */
  function deleteCard($card) {
    view.images.splice($card.dataset.fileIndex, 1);
    renderImages();
  }

  /**
   * Move card up in list.
   */
  function moveCardUp($card) {
    if (!$card.previousSibling) return;
    if ($card.previousSibling.dataset.isLocked) return;
    swapCards($card, $card.previousSibling);
  }

  /**
   * Move card down in list.
   */
  function moveCardDown($card) {
    if (!$card.nextSibling) return;
    swapCards($card, $card.nextSibling);
  }

  /**
   * Move card to any card position in list.
   */
  function moveCard($card) {
    for (let _$card of $images.querySelector('[data-preview-list]').childNodes) {
      if (isMovableCard($card, _$card)) return swapCards($card, _$card);
    }
  }

  /**
   * Swap positions between two cards.
   */
  function swapCards($card, _$card) {
    var _fileIndex = _$card.dataset.fileIndex;
    var fileIndex = $card.dataset.fileIndex;
    var image = view.images[fileIndex];
    var _image = view.images[_fileIndex];
    var $parent = $card.parentNode;
    var $holder = document.createElement('div');
    var _$holder = document.createElement('div');
    var $time = $card.querySelector('[data-preview-field-time]');
    var _$time = _$card.querySelector('[data-preview-field-time]');
    $card.dataset.fileIndex = _fileIndex;
    _$card.dataset.fileIndex = fileIndex;
    view.images[_fileIndex] = image;
    view.images[fileIndex] = _image;
    $time.value = getTime(_fileIndex, true);
    _$time.value = getTime(fileIndex, true);
    $parent.replaceChild($holder, $card);
    $parent.replaceChild(_$holder, _$card);
    $parent.replaceChild(_$card, $holder);
    $parent.replaceChild($card, _$holder);
  }

  /**
   * Check if cards can do valid swap.
   */
  function isMovableCard($card, _$card) {
    if ($card.isSingleUpload) return false;
    if (_$card.isSingleUpload) return false;
    var time = getTime($card.dataset.fileIndex);
    var _time = getTime(_$card.dataset.fileIndex);
    var isSameCard = $card === _$card;
    var isTimeConflicting = time === _time;
    return !isSameCard && isTimeConflicting;
  }

  /**
   * Get time from index.
   */
  function getTime(index, format) {
    var time =
      Number($images.querySelector('[data-field-start-time]').value) +
      Number(index) * Number($images.querySelector('[data-field-time-interval]').value);
    if (format) return pad(time, 2) + ':00';
    else return time;
  }

  /**
   * Pad number with zeros.
   */
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  function appendSeparator(listIndex) {
    var selectedIndex = $images.querySelector('[data-field-begin-at]').selectedIndex;
    if (selectedIndex === -1) return $separator.classList.add('hide');
    var $selectedOption = $images.querySelector('[data-field-begin-at]').options[selectedIndex];
    if (Number($selectedOption.text.split(':')[0]) === getTime(listIndex)) {
      $images.querySelector('[data-preview-list]').appendChild($separator);
      $separator.querySelector('span').innerText = getTime(listIndex, true);
      $separator.classList.remove('hide');
    }
  }

  function appendBeginAtOption(listIndex) {
    var $beginAtOption = document.createElement('option');
    $beginAtOption.text = getTime(listIndex, true);
    if (view.begin_at == getTime(listIndex)) $beginAtOption.selected = 'selected';
    $images.querySelector('[data-field-begin-at]').add($beginAtOption);
  }
}
