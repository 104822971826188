window.onload = function onWindowLoad() {
  if (window.ids) initBulkEditModal();
}

function initBulkEditModal() {
  $('body').on('shown.bs.modal', '.bulkEditModal', initSelect2InModal);
  $('.bulkEditModal select').on('change', handleChangeStatus);
  openModalById('bulk-edit-0');
}

function triggerBulkEditRedirect() {
  window.ids = (new FormData(document.querySelector('form'))).getAll('ids[]');
  if (!window.ids.length) return alert(window.locale['No items selected']);
  if (!window.location.search) return (window.location.search = '?ids=' + window.ids);
  if (!window.location.search.includes('ids=')) return (window.location.search += '&ids=' + window.ids);
  if (window.location.search.includes('ids=')) return updateQueryParam('ids', window.ids);
}

function openModalById(id, showId) {
  var $allModals = $('.bulkEditModal');
  $allModals.modal('hide');
  
  setTimeout(function () {
    var $modal = $('.bulkEditModal[data-id="' + id + '"]');
    var $select = $modal.find('select[data-name="status"]');
    if (showId) showModalContent($modal, showId);
    handleChangeStatus.apply($select);
    $modal.modal('show');
  }, 500);
}

function showModalContent($modal, showId) {
  var $allShowElements = $modal.find('[data-show-id]');
  var $allHideElements = $modal.find('[data-hide-id]');

  $allShowElements.each(function() {
    var $showElement = $(this);
    if ($showElement.data('showId') === showId) $showElement.removeClass('hide');
    else $showElement.addClass('hide');
  });
  $allHideElements.each(function() {
    var $hideElement = $(this);
    if ($hideElement.data('hideId') === showId) $hideElement.addClass('hide');
    else $hideElement.removeClass('hide');
  });
}

function handleChangeStatus() {
  var $select = $(this);
  var $modal = $select.closest('.bulkEditModal');
  var $messageModal = $('.bulkEditModal[data-id="bulk-edit-message"]');
  var newStatusId = +$select.val();
  var $items = $modal.find('tr');
  var $primaryButton = $modal.find('button[data-name="primary"]');
  if (newStatusId) $primaryButton.removeAttr('disabled');
  else $primaryButton.attr('disabled');
  
  $items.each(function updateItemStatus() {
    var $item = $(this);
    var oldStatusId = +$item.find('input[data-name="old-status-id"]').val();
    var statusId = newStatusId || oldStatusId;
    var $badges = $item.find('.badge');
    var $badge = $item.find('.badge[data-id=' + statusId + ']');
    var $newStatusId = $item.find('input[data-name="new-status-id"]');
    var $messageModalItem = $messageModal.find('tr[data-id="'+ $item.data('id') +'"]');
    var $messageModalBadges = $messageModalItem.find('.badge');
    var $messageModalBadge = $messageModalItem.find('.badge[data-id=' + statusId + ']');;
    $newStatusId.val(statusId);
    $badges.addClass('hide');
    $badge.removeClass('hide');
    $messageModalBadges.addClass('hide');
    $messageModalBadge.removeClass('hide');
  })
}

function submitBulkEdit() {
  var form = document.querySelector('form');
  var formData = new FormData(form);
  var request = new XMLHttpRequest();
  openModalById('bulk-edit-message', 'updating');
  formData.append('action', 'edit-bulk');
  request.addEventListener("load", onRequestLoad.bind(request));
  request.open('POST', window.location.pathname);
  request.send(formData);
}

function onRequestLoad() {
  if (this.status === 200) openModalById('bulk-edit-message', 'success');
  else if (this.status === 401) openModalById('bulk-edit-message', 'denied');
  else openModalById('bulk-edit-message', 'error');
}

function initSelect2InModal() {
  var $bulkEditModal = $(this);
  $bulkEditModal.find('select').each(function() {
    var $select = $(this);
    $select.select2({
      templateResult: getSelect2Template,
      dropdownParent: $bulkEditModal,
      width: '100%'
    });
  });
}

function getSelect2Template(state) {
  if (!state.id) return state.text;
  if (!state.element.dataset.color) return state.text;
  var color = state.element.dataset.color;
  var id = state.element.value;
  var $state = $('<span class="badge" data-id="' + id +'" style="background-color: ' + color + '">' + state.text + '</span>');
  return $state;
}

function updateQueryParam(key, value) {
  var params = window.location.search.replace('?', '').split('&');
  for (var i = 0; i < params.length; i++) {
    var param = params[i].split('=');
    if (param[0] === key) params[i] = key + '=' + value;
  }
  window.location.search = '?' + params.join('&');
}

function removeQueryParam(key) {
  var params = window.location.search.replace('?', '').split('&');
  for (var i = 0; i < params.length; i++) {
    var param = params[i].split('=');
    if (param[0] === key) params.splice(i, 1);
  }
  window.location.search = '?' + params.join('&');
}