$(function() {
	$(document.body).on('click', '#reset-project-expiry-time', function() {
		const $self = $(this);
		const id = $self.data('project');

		if (!id) return;

		$.post('/projects/' + id + '/reset_expiry_time', (res) => {
			$self.closest('.input-group').find('input[type="text"]').val(res.time);
			$self.parent().remove();
		});
	});
});