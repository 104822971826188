import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/xml/xml'
import noUiSlider from 'nouislider'

let intervalId;
let query_parts = window.location.search.substr(1).split('&');

$(function () {
  if ($('.breadcrumb').length) addBreadcrumbs();
  $('body').on('select2:select', 'select', onSelect2Select);
  $(document).allSelect2(setDefaultSelectOption);
  $('.content').on('click', 'th .select2', (e) => e.preventDefault());
  $('.content').on('change', 'th select.filter', onChangeThSelectFilter);
  $('.content').on('change', 'span select.filter', onChangeSpanSelectFilter);
  $('.row-repeater').on('click', '.add', onClickAdd);
  $('.row-repeater').on('click', '.remove', onClickRemove);
  check_repeater_count();
  $('.row-repeater').on('change', '.field-select', onChangeFieldSelect);
  $('.row-repeater > .row').each(() => $('.field-select').trigger('change'));
  if ($('[data-condition]').length > 0) showHideConditions();
  if ($('[data-toggle]').length > 0) showHideToggles();
  $('.codemirror').each(initCodeMirror);
  if (typeof noUiSlider !== 'undefined') $('.slider').each(initNoUiSlider);
  if ($('.sortable').length) $('.sortable').sortable({ handle: '.handle' }); // Table headers and checkboxes
  $('table').on('change', 'thead input[type="checkbox"]', onChangeTheadCheckbox);
  $('table tbody').shiftSelectable();
  $('table').on('click', '.copy-link', onClickCopyLink);
  $('th[data-sortable]').each(wrapSortableLink);
  $('.card-body').on('change', '.type-select', onChangeTypeSelect);
  $('.type-select').trigger('change');
  $('.toggle-password').click(onClickTogglePassword);
  $('.checkbox-dropdown').on('click', (e) => e.stopPropagation());
  $('select[name="segment"]').on('change', onChangeSelectSegment);
  $('.content').on('change', '.field-select', function () {});
  $('.form-horizontal').on('change', '#interval', onChangeInterval);
  $('#interval').trigger('change');
  $('.form-horizontal').on('change', '#action', onChangeAction);
  $('#action').trigger('change');
  if ($('.flatpickr-time-h').length) initFlatpickr();
  $('#right-mobile-menu-toggle').click(onClickRightMobileMenuToggle);
  $(window).click(onClickWindow);
  $('#right-mobile-menu-wrapper').click((event) => event.stopPropagation());
  $('#reset-date-end').click(() => $('#date_end').val('')); //remove value from end date picker.
  $('.form-horizontal').on('change', '.form-control-file', onChangeFormControlFile);
  if ($('#api-sync').length > 0 && $('#api-sync').hasClass('disabled'))
    intervalId = setInterval(() => pollSyncStatus($('#api-sync').data('project_id')), 3000);
  $('#api-sync').on('click', onClickApiSync);
});

$.fn.shiftSelectable = customShiftSelectable;
$.fn.allSelect2 = customAllSelect2;
$.fn.rebindColorPickerEvents = customRebindColorPickerEvents;

function onChangeSelectSegment() {
  document.location = '?' + $(this).closest('fieldset').serialize();
}

/**
 * Show filename when uploading.
 */
function onChangeFormControlFile() {
  const name = $(this)
    .val()
    .match(/[^\/\\]+$/)[0];
  const $path = $(this).closest('.input-group').find('.show-path');
  $path.val(name);
}

function onChangeTheadCheckbox() {
  $(this)
    .closest('table')
    .find('tbody input[type="checkbox"]')
    .prop('checked', $(this).prop('checked'));
}

function onClickApiSync(e) {
  e.preventDefault();
  if ($(e.target).hasClass('disabled')) return;
  $.post({
    url: '/projects/sync',
    data: {
      project_id: $(e.target).data('project_id'),
    },
    success: function (res) {
      if (res.success) {
        $(e.target).addClass('disabled');
        $(e.target).find('.zmdi').addClass('zmdi-hc-spin-reverse');
        intervalId = setInterval(function () {
          pollSyncStatus($(e.target).data('project_id'));
        }, 3000);
      }
      $('.notices').remove();
      $('.page-titles').after(
        '<div class="notices"><div class="alert alert-' +
          (res.success ? 'success' : 'danger') +
          '">' +
          res.message +
          '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><i class="zmdi zmdi-close"></i></button></div></div>'
      );
    },
  });
}

function customShiftSelectable() {
  var lastChecked,
    $container = this;
  $container.on('click', 'input[type="checkbox"]', function (e) {
    if (!lastChecked) {
      lastChecked = $(this);
      return;
    }
    if (e.shiftKey) {
      var $boxes = $container.find('input[type="checkbox"]'),
        start = $boxes.index(this),
        end = $boxes.index(lastChecked[0]);
      $boxes
        .slice(Math.min(start, end), Math.max(start, end) + 1)
        .prop('checked', lastChecked.prop('checked'))
        .trigger('change');
    }
    lastChecked = $(this);
  });
}

function customAllSelect2(callback) {
  var container = this;
  container.find('select').each(function () {
    var select = $(this);
    var settings = {
      dropdownAutoWidth: true,
      width: '100%',
      tags: select.data('tags') === 'true',
      language: {
        noResults: function () {
          return $('body').hasClass('lang-sv') ? 'Inga träffar' : 'No results found';
        },
        searching: function () {
          return $('body').hasClass('lang-sv') ? 'Söker…' : 'Searching…';
        },
      },
    };
    if (select.data('search') === false) settings.minimumResultsForSearch = -1;
    if (select.data('width')) settings.width = select.data('width');
    select.select2(settings);
    if (callback && typeof callback === 'function') {
      setTimeout(function () {
        callback(select);
      }, 0);
    }
  });
}

function customRebindColorPickerEvents() {
  var container = this;
  container.find('.color-picker').each(function () {
    var horizontal = $(this).data('horizontal') || false;
    $(this).colorpicker({ horizontal: horizontal });
  });
}

function addBreadcrumbs() {
  var regex = new RegExp('/[^/]*$'),
    page_path = window.location.pathname,
    current_path = page_path,
    home = $('.breadcrumb li').first(),
    item;
  do {
    item = $('.navigation a[href="' + current_path + '"]');
    if (item.length) break;
    if (!current_path.match(regex)) break;
    current_path = current_path.replace(regex, '');
  } while (!item.length);
  item.parentsUntil('.navigation', 'li').each(function () {
    var a = $(this).find('> a'),
      new_crumb = home.clone();
    if (a.attr('href') === '/') return;
    new_crumb.find('a').attr('href', a.attr('href')).text(a.text());
    home.after(new_crumb);
  });
  if (!$('.breadcrumb a[href="' + page_path + '"]').length) {
    var new_crumb = home.clone();
    new_crumb.find('a').attr('href', page_path).text($('h1, h2, h3').first().text());
    $('.breadcrumb').append(new_crumb);
  }
}

function initCodeMirror() {
  var mode = 'xml';
  $(this)
    .prop('classList')
    .forEach(function (e) {
      if (e.indexOf('mode-') == 0) {
        mode = e.substring(5);
      }
    });
  CodeMirror.fromTextArea($(this)[0], {
    lineNumbers: !$(this).attr('readonly'),
    mode: mode,
    htmlMode: true,
    readOnly: $(this).attr('readonly'),
  });
}

function initNoUiSlider(index, slider) {
  var valueField = $(slider).siblings('.input-slider-value');
  noUiSlider.create(slider, {
    start: [valueField.val()],
    connect: 'lower',
    range: {
      min: 0,
      max: 1,
    },
    tooltips: [true],
  });
  slider.noUiSlider.on('update', function (values, handle) {
    valueField.val(values[handle]);
  });
}

function onSelect2Select(e) {
  var select = $(this),
    data = e.params.data,
    option = $(data.element),
    group = option.closest('optgroup');
  if (!group.length) return;
  select
    .next('span.select2')
    .find('.select2-selection__rendered')
    .text(group.attr('label') + ': ' + data.text);
}

function setDefaultSelectOption(select) {
  var option = select.find('option:selected');
  select.trigger({
    type: 'select2:select',
    params: {
      data: {
        element: option[0],
        text: option.text(),
      },
    },
  });
}

function onChangeThSelectFilter(e) {
  var url = new URL(location.href);
  url.searchParams.set($(this).data('id'), $(this).val());
  location.href = url;
}

function onChangeSpanSelectFilter(e) {
  var url = new URL(location.href);
  url.searchParams.set($(this).data('id'), $(this).val());
  location.href = url;
}

function onClickAdd() {
  var last_row = $(this).closest('.row-repeater').find('.row:last'),
    new_row = last_row.clone(false);
  new_row.find(':input:not(select)').val('');
  new_row.find('.select2-container').remove();
  new_row.find('select').prop('selectedIndex', 0);
  new_row.find('.no-edit').prop('readonly', false);
  last_row.after(new_row);
  new_row.allSelect2();
  new_row.rebindColorPickerEvents();
  check_repeater_count();
}

function onClickRemove() {
  $(this).closest('.row').remove();
  check_repeater_count();
}

function check_repeater_count() {
  $('.row-repeater').each(function () {
    var repeater = $(this);
    var disabled = repeater.find('.row').length === 1;
    repeater.find('.remove').prop('disabled', disabled);
    $(this).trigger('checked-repeater-count');
  });
}

function onChangeFieldSelect() {
  var field_select = $(this),
    row = field_select.closest('.row'),
    operator_select = row.find('.operator-select'),
    field = field_select.find('option:selected').data('field');
  operator_select.find('option').each(function () {
    var option = $(this);
    var operators = option.data('field-operator').split(',');
    $.each(operators, function (i, operator) {
      if (operator !== field) {
        option.attr('disabled', 'disabled');
        return true;
      }
      option.removeAttr('disabled');
      return false;
    });
  });
  row.allSelect2();
}

function showHideToggles() {
  toggleShow();
  $('body').on('change', 'input[data-toggle]', toggleShow);
}

function showHideConditions() {
  $('body').on('change', 'input[data-condition]', function () {
    $('[data-condition-hide~="' + $(this).data('condition') + '"]').addClass('hide');
    $('[data-condition-show~="' + $(this).data('condition') + '"]').removeClass('hide');
  });
  $('body').on('change', 'select[data-condition]', function () {
    $('[data-condition-hide~="' + $(this).val() + '"]').addClass('hide');
    $('[data-condition-show~="' + $(this).val() + '"]').removeClass('hide');
  });
  $('[data-condition-show]').addClass('hide');
  $('input[data-condition]:checked').each(function () {
    $('[data-condition-hide~="' + $(this).data('condition') + '"]').addClass('hide');
    $('[data-condition-show~="' + $(this).data('condition') + '"]').removeClass('hide');
  });
  $('select[data-condition]').each(function () {
    $('[data-condition-hide~="' + $(this).val() + '"]').addClass('hide');
    $('[data-condition-show~="' + $(this).val() + '"]').removeClass('hide');
  });
}

function toggleShow() {
  $('[data-toggle-show]').each(function () {
    $(this).addClass('hide');
  });
  $('input[data-toggle]:checked').each(function () {
    $('[data-toggle-show*="' + $(this).data('toggle') + '"]').removeClass('hide');
  });
}

function onClickCopyLink() {
  $(this).siblings('input').select();
  document.execCommand('copy');
}

function wrapSortableLink() {
  var th = $(this),
    name = th.data('sortable'),
    params = {},
    classes = ['sortable'];
  for (var i = 0; i < query_parts.length; i++) {
    var temp = query_parts[i].split('=');
    if (!temp[0]) continue;
    params[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
  }
  if (params.orderby == name) {
    classes.push('current');
    if (params.order != 'desc') {
      classes.push('asc');
      params.order = 'desc';
    } else {
      classes.push('desc');
      params.order = 'asc';
    }
  } else {
    params.order = 'asc';
  }
  params.orderby = name;
  $(this).wrapInner('<a class="' + classes.join(' ') + '" href="?' + $.param(params) + '"></a>');
}

function onChangeTypeSelect() {
  //Shows input fields based on data type when creating or editing a reasorce
  var data = $(this).find('option:selected').data('type').split(',');
  $('.settings-row').hide();
  $.each(data, function (i, id) {
    var input_field = $('#' + id);
    input_field.closest('.row').show();
  });
}

function onClickTogglePassword() {
  //Hides or shows password
  $(this).toggleClass('zmdi-eye zmdi-eye-off');
  var input = $($(this).attr('data'));
  if (input.attr('type') == 'password') input.attr('type', 'text');
  else input.attr('type', 'password');
}

function onChangeInterval() {
  //Shows diferent inputfields to choose interval
  var interval = $(this),
    type = interval.find('option:selected').val();
  $('.interval-type').hide();
  $('.interval-type').find('select').prop('disabled', true);
  $('.interval-type').find('input').prop('disabled', true);
  $('.' + type).show();
  $('.' + type)
    .find('select')
    .prop('disabled', false);
  $('.' + type)
    .find('input')
    .prop('disabled', false);
}

function onChangeAction() {
  var action = $(this),
    type = action.find('option:selected').val();
  $('.action-type').hide();
  $('.action-type').find('select').prop('disabled', true);
  $('.action-type').find('input').prop('disabled', true);
  $('.' + type).show();
  $('.' + type)
    .find('select')
    .prop('disabled', false);
  $('.' + type)
    .find('input')
    .prop('disabled', false);
}

function initFlatpickr() {
  $('.flatpickr-time-h').flatpickr({
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H',
    time_24hr: true,
    defaultDate: '"' + $('.flatpickr-time-h').data('hour') + '"',
  });
  $('body').addClass('flatpickr-h');
}

function onClickRightMobileMenuToggle() {
  if ($('#right-mobile-menu-wrapper').hasClass('toggle-right-mobile-menu')) {
    $('#right-mobile-menu-wrapper').removeClass('toggle-right-mobile-menu');
    console.log();
  } else {
    $('#right-mobile-menu-wrapper').addClass('toggle-right-mobile-menu');
    console.log();
  }
  event.stopPropagation();
}

function onClickWindow() {
  if ($('#right-mobile-menu-wrapper').hasClass('toggle-right-mobile-menu')) {
    $('#right-mobile-menu-wrapper').removeClass('toggle-right-mobile-menu');
    console.log();
  }
}

function pollSyncStatus(projectId) {
  $.get({
    url: '/projects/get_sync_status',
    data: { project_id: projectId },
    success: function (res) {
      if (!res.syncing) {
        clearInterval(intervalId);
        $('#api-sync').removeClass('disabled');
        $('#api-sync').find('.zmdi').removeClass('zmdi-hc-spin-reverse');
        $('.notices').remove();
        $('.page-titles').after(
          '<div class="notices"><div class="alert alert-success">' +
            res.message +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><i class="zmdi zmdi-close"></i></button></div></div>'
        );
      }
    },
  });
}
