let selectedGroup = 'tenants';

if (window.location.pathname.includes('/users/edit/')) {
  $(document.body).on('change', '#add_capability input[name="type"]', toggleGroup);
  $(document.body).on('click', '#add_capability button', addCapability);
}

function toggleGroup() {
  const $selectedGroup = $(this);
  selectedGroup = $selectedGroup.val();
  $('#add_capability .group-projects').hide();
  $('#add_capability .group-tenants').hide();
  $(`#add_capability .group-${selectedGroup}`).show();
}

function addCapability() {
  const group = selectedGroup.slice(0, -1);
  const $itemSelect = $(`select[data-name="${group}_id"]`);
  const $itemOption = $itemSelect.find('option:selected');
  const $capabilitySelect = $(`select[data-name="${group}_cap"]`);
  const $capabilityOption = $capabilitySelect.find('option:selected');
  const itemId = +$itemSelect.val();
  const itemName = $itemOption.text().replace($itemOption.data('trim'), '').trim();
  const capabilityId = $capabilitySelect.val();
  const capabilityName = $capabilityOption.text()
  if (!capabilityId) return;

  $('.current-tenant-capabilities').append(
    '<li class="checkbox list-group-item">' +
      `<input type="hidden" name="caps_${group}[${itemId}][${capabilityId}]" value="1" />` +
      `<input type="checkbox" id="caps_${group}_${itemId}_${capabilityId}" name="caps_${group}[${itemId}][${capabilityId}]" value="0" />` +
      `<label class="checkbox__label" for="caps_${group}_${itemId}_${capabilityId}">${itemName}: ${capabilityName}</label>` +
    '</li>'
  );
}
